import React from 'react'
import styled from 'styled-components'
import App from 'App'
import { COLOR_CONSTANTS, space, colors, radius } from 'theme'
import { CONTAINER_MAX_WIDTH, FREQUENTLY_ASKED_QUESTIONS } from 'consts'
import SEO from 'components/SEO'
import { Flex, Grid, Box } from 'components/Layout'
import Container from 'components/Container'
import { Text, H1, H2, H3 } from 'components/Typography'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'

import imageHeader from 'static/images/social-media-tools/header_picture.webp'
import imageDoubleArrowRightBlue from 'static/images/home/double_arrow_right_blue.svg'

import { transparentize } from 'polished'
import GridDropdown from '../Faq/GridDropdown'
import GetStartedSection from './GetStartedSection'

import { FREE_TOOLS } from '../../tools'

import Detail from './components/Detail'
import BreadCrumbs from '../../components/BreadCrumbs'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  overflow-x: hidden;
`

const StyledNetworkItemWrapper = styled(Flex)`
  background: ${colors.white};
  box-shadow: 0px 2px 4px rgba(37, 64, 102, 0.08), 0px 8px 50px rgba(118, 139, 168, 0.14);
  border-radius: ${radius.xxl};
  padding: ${space.m};
  cursor: pointer;
  transition: all 0.5s ease;
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0px 0px 8px rgb(145 158 171 / 20%);
  }
`

const StyledLinkText = styled(Text)`
  display: flex;
  align-items: center;
`

const RoundedBox = styled(Box)`
  position: absolute;
  background: rgba(166, 199, 245, 0.2);
  border-radius: ${radius.pill};
  z-index: 0;
`

const StyledBadgeWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: ${transparentize(0.85, colors.primary)};
  color: ${colors.primary};
  padding: ${space.xs} ${space.s};
  border-radius: ${radius.l};
`

const { CAPTION_ID, HASHTAG_ID, USERNAME_ID, OTHER_ID } = {
  CAPTION_ID: 'caption_id',
  HASHTAG_ID: 'hashtag_id',
  USERNAME_ID: 'usename_id',
  OTHER_ID: 'other_id',
}

const GENERATOR_TAGS = [
  { label: 'CAPTION', value: CAPTION_ID },
  { label: 'HASHTAG', value: HASHTAG_ID },
  { label: 'USERNAME', value: USERNAME_ID },
  { label: 'OTHER', value: OTHER_ID },
]

const Tools = () => {
  return (
    <App fullHeader>
      <SEO
        title="Free tools to help you grow your social media presence"
        description="Vista Social centralizes publishing, monitoring, engagement, listening and analytics across the social platforms and customer management tools that power your social business."
      />

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex flexDirection="column" alignItems="center">
            <Grid
              mt="m"
              mb="m"
              pt={{ mobile: 0, desktop: 'm' }}
              gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
              gridGap="l"
              width="100%"
              alignItems="center"
            >
              <Flex width="100%" justifyContent="center" alignItems="center">
                <Flex flexDirection="column">
                  <Flex
                    width="100%"
                    justifyContent="center"
                    flexDirection="column"
                    maxWidth={{ tablet: '100%', desktop: '558px' }}
                  >
                    <Flex
                      alignItems="center"
                      display={{ mobile: 'none', mobileLarge: 'flex' }}
                      justifyContent={{ mobile: 'center', desktop: 'flex-start' }}
                    >
                      <BreadCrumbs
                        data={[
                          { label: 'Vista Social', path: '/' },
                          { label: 'Free Tools', path: '/social-media-tools/' },
                        ]}
                      />
                    </Flex>
                    <Flex
                      mt="l"
                      flexDirection={{ mobile: 'row', desktop: 'column' }}
                      flexWrap="wrap"
                      justifyContent="center"
                    >
                      <H1
                        fontSize="4xl"
                        fontWeight="bold"
                        color={COLOR_CONSTANTS.DENIM}
                        textAlign={{ mobile: 'center', desktop: 'left' }}
                      >
                        <H1
                          as="span"
                          fontSize="4xl"
                          fontWeight="bold"
                          color="primary"
                          textAlign={{ mobile: 'center', desktop: 'left' }}
                        >
                          Free tools
                        </H1>{' '}
                        to Help you Grow your Social Media Presence
                      </H1>
                    </Flex>
                    <H2
                      mt="m"
                      pt="s"
                      color="secondaryText"
                      fontSize="l"
                      textAlign={{ mobile: 'center', desktop: 'left' }}
                      fontWeight="normal"
                    >
                      Managing and growing your brand online is a hard job. Vista Social's free tools for creators and
                      social media pros save you time and make your job a little easier. Make sure to bookmark this page
                      and come back often.
                    </H2>
                    <Flex
                      mt="m"
                      pt="s"
                      alignItems="center"
                      justifyContent={{ mobile: 'center', desktop: 'flex-start' }}
                      flexDirection={{ mobile: 'column', tablet: 'row' }}
                    >
                      <Text color="secondaryText" fontSize="s">
                        GENERATORS:
                      </Text>
                      <Grid
                        mt={{ mobile: 's', tablet: 0 }}
                        gridTemplateColumns={`repeat(${GENERATOR_TAGS.length}, 1fr)`}
                        gridGap="m"
                        ml="m"
                      >
                        {GENERATOR_TAGS.map(({ value, label }) => (
                          <StyledBadgeWrapper
                            key={value}
                            onClick={() => {
                              const el = document.getElementById(value)
                              if (el) {
                                el.scrollIntoView({
                                  behavior: 'smooth',
                                })
                              }
                            }}
                          >
                            <Text fontSize="xs" fontWeight="medium">
                              {label}
                            </Text>
                          </StyledBadgeWrapper>
                        ))}
                      </Grid>
                    </Flex>
                  </Flex>
                </Flex>
              </Flex>
              <Flex width="100%" ml="auto" mr={{ mobile: 'auto', desktop: 0 }} mt={{ mobile: 'l', desktop: 0 }}>
                <LocalImage
                  src={imageHeader}
                  width="100%"
                  mx="auto"
                  maxWidth="504px"
                  alt="Free tools to help you grow your social media presence"
                />
              </Flex>
            </Grid>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper bg={COLOR_CONSTANTS.SALT}>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'l' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <Flex maxWidth="920px" mx="auto" flexDirection="column">
            <H3
              mt="m"
              pt="s"
              fontSize="xxxl"
              textAlign="left"
              id={CAPTION_ID}
              color={COLOR_CONSTANTS.DENIM}
              fontWeight="medium"
            >
              Social media{' '}
              <H3 as="span" fontSize="xxxl" color="primary" textAlign="left" fontWeight="medium">
                caption
              </H3>{' '}
              generators
            </H3>
            <Grid
              mt="l"
              pt="m"
              gridGap="l"
              gridTemplateColumns={{ mobile: 'repeat(1, 1fr)', tablet: 'repeat(2, 1fr)' }}
            >
              {FREE_TOOLS.filter((item) => item.type === 'caption').map((tool) => (
                <Detail tool={tool} key={tool.name} />
              ))}
            </Grid>
          </Flex>

          <Flex mt="xxl" maxWidth="920px" mx="auto" flexDirection="column">
            <H3
              mt="m"
              pt="s"
              fontSize="xxxl"
              textAlign="left"
              id={HASHTAG_ID}
              color={COLOR_CONSTANTS.DENIM}
              fontWeight="medium"
            >
              Social media{' '}
              <H3 as="span" fontSize="xxxl" color="primary" textAlign="left" fontWeight="medium">
                hashtag
              </H3>{' '}
              generators
            </H3>

            <Grid
              mt="l"
              pt="m"
              gridGap="l"
              gridTemplateColumns={{ mobile: 'repeat(1, 1fr)', tablet: 'repeat(2, 1fr)' }}
            >
              {FREE_TOOLS.filter((item) => item.type === 'hashtag').map((tool) => (
                <Detail tool={tool} key={tool.name} />
              ))}
            </Grid>
          </Flex>

          <Flex mt="xxl" maxWidth="920px" mx="auto" flexDirection="column">
            <H3
              mt="m"
              pt="s"
              fontSize="xxxl"
              textAlign="left"
              id={USERNAME_ID}
              color={COLOR_CONSTANTS.DENIM}
              fontWeight="medium"
            >
              Social media{' '}
              <H3 as="span" fontSize="xxxl" color="primary" textAlign="left" fontWeight="medium">
                username
              </H3>{' '}
              generators
            </H3>

            <Grid
              mt="l"
              pt="m"
              gridGap="l"
              gridTemplateColumns={{ mobile: 'repeat(1, 1fr)', tablet: 'repeat(2, 1fr)' }}
            >
              {FREE_TOOLS.filter((item) => item.type === 'username').map((tool) => (
                <Detail tool={tool} key={tool.name} />
              ))}
            </Grid>
          </Flex>

          <Flex mt="xxl" maxWidth="920px" mx="auto" flexDirection="column">
            <H3
              mt="m"
              pt="s"
              fontSize="xxxl"
              textAlign="left"
              id={OTHER_ID}
              color={COLOR_CONSTANTS.DENIM}
              fontWeight="medium"
            >
              Other{' '}
              <H3 as="span" fontSize="xxxl" color="primary" textAlign="left" fontWeight="medium">
                free
              </H3>{' '}
              tools
            </H3>

            <Grid
              mt="l"
              pt="m"
              gridGap="l"
              gridTemplateColumns={{ mobile: 'repeat(1, 1fr)', tablet: 'repeat(2, 1fr)' }}
            >
              {FREE_TOOLS.filter((item) => !item.type || item.type.startsWith('other')).map((tool) => (
                <Detail tool={tool} key={tool.name} />
              ))}
            </Grid>
          </Flex>
        </Container>
      </Wrapper>

      <Wrapper>
        <Container
          pl={{ mobile: 'l', tablet: 'l' }}
          pr={{ mobile: 'l', tablet: 'l' }}
          py={{ mobile: 'l', tablet: 'xxl' }}
          position="relative"
          maxWidth={CONTAINER_MAX_WIDTH}
          width="100%"
          height="100%"
        >
          <H3 fontSize="3xl" fontWeight="bold" color={COLOR_CONSTANTS.DENIM} textAlign="center">
            Frequently Asked{' '}
            <H1 as="span" fontSize="3xl" fontWeight="bold" color="primary">
              Questions
            </H1>
          </H3>
          <Flex flexDirection="column" mt="xl" width="100%">
            <GridDropdown faqs={FREQUENTLY_ASKED_QUESTIONS.all.slice(0, 3)} />
            <Flex mt="l" justifyContent="flex-end">
              <StyledLinkText as="a" href="/faq" textAlign="left">
                <Text fontSize="l" fontWeight="medium" textAlign="left" color="primary" as="span">
                  ALL OUR FAQS
                </Text>
                <LocalImage src={imageDoubleArrowRightBlue} mt="-2px" />
              </StyledLinkText>
            </Flex>
          </Flex>
        </Container>
      </Wrapper>

      <GetStartedSection />

      <Footer />
    </App>
  )
}

export default Tools
