import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { COLOR_CONSTANTS, space, colors, radius } from 'theme'

import { Flex } from 'components/Layout'
import { Text } from 'components/Typography'

import LocalImage from 'components/LocalImage'

import imageDoubleArrowRightBlue from 'static/images/home/double_arrow_right_blue.svg'
import imageAI from 'static/images/social-media-tools/ai.svg'

import { FREE_TOOLS_UI } from '../consts'

const StyledNetworkItemWrapper = styled(Flex)`
  height: 100%;
  background: ${colors.white};
  box-shadow: 0px 2px 4px rgba(37, 64, 102, 0.08), 0px 8px 50px rgba(118, 139, 168, 0.14);
  border-radius: ${radius.xxl};
  padding: ${space.m};
  cursor: pointer;
  transition: all 0.5s ease;
  &:hover {
    transform: translateY(-3px);
    box-shadow: 0px 0px 8px rgb(145 158 171 / 20%);
  }
`

const StyledLinearWrapper = styled(Flex)`
  position: relative;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(90.77deg, #318afe 13.61%, #0057d9 108.2%);
  padding: 10px 18px 10px 10px;
  border-radius: ${radius.xl};
`

const Detail = ({ tool }) => {
  // eslint-disable-next-line camelcase
  const { name = '', path = '', description = '', ui_key = '' } = tool

  let imageStart
  let imageBackground
  let imageEnding

  if (FREE_TOOLS_UI[ui_key]) {
    ;({ imageStart, imageBackground, imageEnding } = FREE_TOOLS_UI[ui_key])
  } else {
    ;({ imageStart, imageBackground, imageEnding } = FREE_TOOLS_UI.default)
  }

  return (
    <Flex flexDirection="column">
      <Flex width="100%" position="relative">
        <LocalImage src={imageStart} width="52px" height="52px" />
        <StyledLinearWrapper ml="xs" width="100%">
          <LocalImage src={imageAI} width="32px" height="32px" zIndex="1" />
          <Flex position="absolute" top="0" left="28px">
            <LocalImage src={imageBackground} zIndex="0" />
          </Flex>
          <LocalImage src={imageEnding} width="initial" height="initial" />
        </StyledLinearWrapper>
      </Flex>
      <StyledNetworkItemWrapper
        mt="xs"
        flexDirection="column"
        key={name}
        maxWidth={{ mobile: '100%', tablet: '445px' }}
        width="100%"
        onClick={() => {
          window.open(path, '_self')
        }}
      >
        <Flex flexDirection="column" p="s">
          <Flex alignItems="center">
            <Flex flexDirection="column" width="100%">
              <Flex justifyContent="space-between" alignItems="center" width="100%">
                <Text color={COLOR_CONSTANTS.DENIM} fontWeight="bold" fontSize="xl" textAlign="left">
                  {name}
                </Text>
                <Text as="a" href={path}>
                  <LocalImage src={imageDoubleArrowRightBlue} />
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Text mt="m" fontSize="m" color="secondaryText" textAlign="left">
            {description}
          </Text>
        </Flex>
      </StyledNetworkItemWrapper>
    </Flex>
  )
}

Detail.defaultProps = {}

Detail.propTypes = {
  tool: PropTypes.object.isRequired,
}

export default Detail
