import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { COLOR_CONSTANTS, colors } from 'theme'
import { Flex } from 'components/Layout'
import Link from 'components/Link'
import LocalImage from 'components/LocalImage'
import { Text } from 'components/Typography'

import imageArrow from 'static/images/arrow_right_dark.svg'

const StyledLinkText = styled(Text)`
  cursor: pointer;
  text-decoration: none;
  &:hover {
    color: ${colors.primary};
  }
`
const BreadCrumbs = ({ data }) => {
  const [firstElements, setFirstElements] = useState(null)
  const [lastElement, setLastElement] = useState(null)

  useEffect(() => {
    if (data.length > 1) {
      setFirstElements(data.slice(0, data.length - 1))
      setLastElement(data[data.length - 1])
    } else {
      setLastElement(data[0])
    }
  }, [data])

  return (
    <Flex alignItems="center" flexWrap="wrap">
      {firstElements && (
        <Flex alignItems="center" mt="s">
          {firstElements.map(({ path, label }) => (
            <Flex key={path} alignItems="center">
              <StyledLinkText as="a" href={path} color={COLOR_CONSTANTS.DENIM} fontWeight="medium" fontSize="m">
                {label}
              </StyledLinkText>
              <LocalImage src={imageArrow} width="24px" height="24px" mx="s" />
            </Flex>
          ))}
        </Flex>
      )}
      {lastElement && (
        <Text color="secondaryText" fontWeight="medium" fontSize="m" mt="s">
          {lastElement.label}
        </Text>
      )}
    </Flex>
  )
}

BreadCrumbs.defaultProps = {}

BreadCrumbs.propTypes = {
  data: PropTypes.array.isRequired,
}

export default BreadCrumbs
